import { Form, Input, InputNumber, Radio, Space } from "antd";

const paymentTypes = [
  {
    value: "prepaid",
    label: "Prepaid",
  },
  {
    value: "postpaid",
    label: "Postpaid",
  },
];

export default function Payment({ permissions }) {
  return (
    <div>
      <Space>
        <Form.Item
          label="Price"
          name="price"
          rules={[
            {
              required: true,
              message: "Please input price",
            },
          ]}
        >
          <InputNumber controls={false} style={{ width: 175 }} />
        </Form.Item>

        <Form.Item
          label="Devices count"
          name="device_service"
          rules={[
            {
              required: true,
              message: "Please input devices count",
            },
          ]}
        >
          <InputNumber controls={false} style={{ width: 175 }} />
        </Form.Item>
      </Space>

      <Form.Item
        label="Payment type"
        name="payment_type"
        rules={[
          {
            required: true,
            message: "Please select payment type",
          },
        ]}
      >
        <Radio.Group buttonStyle="solid">
          {paymentTypes.map((item, index) => (
            <Radio key={index} value={item.value}>
              {item.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      {permissions?.payments?.paypal && (
        <Form.Item label="Paypal Plan Id" name="paypal_plan_id">
          <Input />
        </Form.Item>
      )}

      {permissions?.payments?.stripe && (
        <Form.Item label="Stripe Plan Id" name="stripe_plan_id">
          <Input />
        </Form.Item>
      )}

      {permissions?.payments?.apple && (
        <Form.Item label="Apple Subscription Id" name="apple_subscription_id">
          <Input />
        </Form.Item>
      )}

      {permissions?.payments?.google && (
        <Form.Item label="Google Subscription Id" name="google_subscription_id">
          <Input />
        </Form.Item>
      )}
    </div>
  );
}
