// import { Input, Select } from "antd";
// import { useEffect, useState } from "react";
// import REQUESTS from "../../../api/requests";

// export default function Resolutions({ editable, setSelectedResolutions }) {
//   const [resolutions, setResolutions] = useState([]);
//   const [resolutionOptions, setResolutionOptions] = useState([]);
//   const [selectedResolutionId, setSelectedResolutionId] = useState(null);
//   const [selectedResolution, setSelectedResolution] = useState(null);

//   const getResolutions = () => {
//     REQUESTS.TRANSCODERS.RESOLUTIONS.GET().then((response) => {
//       if (response.error) return;

//       const list = response.message?.rows.map((item) => ({
//         id: item.id,
//         name: item.name,
//         input: "",
//         output: "",
//       }));

//       if (editable) {
//         const editableResolutions = editable.resolutions || [];
//         list.forEach((item) => {
//           const matchingEditable = editableResolutions.find(
//             (editableItem) => editableItem.id === item.id
//           );
//           if (matchingEditable) {
//             item.input = matchingEditable.info.input;
//             item.output = matchingEditable.info.output;
//           }
//         });
//       }

//       const options = list.map((item) => ({
//         label: item.name,
//         value: item.id,
//       }));

//       setResolutionOptions(options);
//       setResolutions(list);

//       if (editable) {
//         const editableResolutions =
//           editable.resolutions?.map((item) => ({
//             id: item.id,
//             input: item.info.input,
//             output: item.info.output,
//           })) || [];
//         setSelectedResolutions(editableResolutions);
//       }
//     });
//   };

//   const handleResolutionSelect = (value) => {
//     setSelectedResolutionId(value);

//     const selected = resolutions.find((item) => item.id === value);
//     setSelectedResolution(selected || null);
//   };

//   const handleInputChange = (e, field) => {
//     const { value } = e.target;

//     const updatedResolution = { ...selectedResolution, [field]: value };
//     setSelectedResolution(updatedResolution);

//     const updatedResolutions = resolutions.map((item) =>
//       item.id === selectedResolutionId ? updatedResolution : item
//     );

//     setResolutions(updatedResolutions);
//   };

//   useEffect(() => {
//     getResolutions();
//   }, []);

//   useEffect(() => {
//     if (selectedResolution) {
//       setSelectedResolutions([selectedResolution]);
//     }
//   }, [selectedResolution, setSelectedResolutions]);

//   useEffect(() => {
//     if (editable && editable.resolutions?.length > 0) {
//       const firstEditable = editable.resolutions[0];
//       handleResolutionSelect(firstEditable.id);
//     }
//   }, [editable]);

//   return (
//     <div style={{ marginBottom: 20 }}>
//       <div style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: 10 }}>
//         <span>Resolutions</span>
//         <Select
//           options={resolutionOptions}
//           onChange={handleResolutionSelect}
//           value={selectedResolutionId || undefined}
//           placeholder="Select a resolution"
//         />
//       </div>

//       {selectedResolution && (
//         <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
//           <Input
//             placeholder="Input"
//             value={selectedResolution.input}
//             onChange={(e) => handleInputChange(e, "input")}
//           />
//           {/* <Input
//             placeholder="Output"
//             value={selectedResolution.output}
//             readOnly
//             style={{ color: "gray", background: "#8080801c" }}
//           /> */}
//         </div>
//       )}
//     </div>
//   );
// }

import { Input } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

export default function Resolutions({ editable, setSelectedResolutions }) {
  const [resolutions, setResolutions] = useState([]);

  const getResolutions = () => {
    REQUESTS.TRANSCODERS.RESOLUTIONS.GET().then((response) => {
      if (response.error) return;

      const list = response.message?.rows.map((item) => {
        return {
          id: item.id,
          input: "",
          name: item.name,
          output: "",
        };
      });

      if (editable) {
        const editableResolutions = editable.resolutions || [];
        for (let i = 0; i < list.length; i++) {
          for (let g = 0; g < editableResolutions.length; g++) {
            if (list[i].id === editableResolutions[g].id) {
              list[i].input = editableResolutions[g].info.input;
              list[i].output = editableResolutions[g].info.output;
            }
          }
        }
      }

      setResolutions(list);
    });
  };

  const onChangeResolutions = (e, name) => {
    const { value } = e.target;

    const _resolution = [...resolutions];

    for (let i = 0; i < _resolution.length; i++) {
      if (_resolution[i].name === name) {
        _resolution[i].input = value;
      }
    }

    setResolutions(_resolution);
  };

  useEffect(() => {
    getResolutions();
  }, []);

  useEffect(() => {
    const list = resolutions.filter((item) => item.input !== "");

    setSelectedResolutions(list);
  }, [resolutions]);

  return (
    <div style={{ marginBottom: 20 }}>
      <span>Resolutions</span>
      {resolutions.map((resolution, index) => {
        return (
          <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
            <Input
              value={resolution.name}
              readOnly
              style={{ color: "gray", background: "#8080801c" }}
            />
            <Input
              placeholder="Input"
              value={resolution.input}
              onChange={(e) => onChangeResolutions(e, resolution.name)}
            />
            <Input
              placeholder="Output"
              value={resolution.output}
              readOnly={true}
              style={{ color: "gray", background: "#8080801c" }}
            />
          </div>
        );
      })}
    </div>
  );
}
