import { useState } from "react";
import { notification } from "antd";
import { useNavigate } from "react-router";

import PATHS from "../../../config/paths";

import REQUESTS from "../../../api/requests";

import Player from "./components/Player";
import MediasTable from "./components/MediasTable";
import GalleryDrawer from "./components/gallery/GalleryDrawer";
import PageComponent from "../../../_components/page/PageComponent";
import { useSearchParams } from "react-router-dom";
import { setInLocal } from "../../../helpers/storages";
import SeriesTable from "./components/SeriesTable";

export default function SeriesPage() {
  const navigate = useNavigate();

  const searchParams = useSearchParams();

  const [getAgain, setGetAgain] = useState(false);

  const [playing, setPlaying] = useState(null);

  const [itemForGalery, setItemForGalery] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.VOD.MEDIAS.GET({ query: JSON.stringify(query) })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: "Something went wrong",
          });
          onError(response.message);
        } else {
          onSuccess(response.message);
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
        onError(error);
      });
  };

  const onEnabled = (value, id) => {
    const type = value ? "enable" : "disable";

    REQUESTS.VOD.MEDIAS.ENABLE_OR_DISABLE(type, { id })
      .then((response) => {
        setGetAgain((prev) => !prev);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const onHighlighted = (value, id) => {
    const body = {
      id: id,
      highlighted: value,
    };

    REQUESTS.VOD.MEDIAS.HIGHLIGHTED(body)
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: response.message,
        });

        setGetAgain((prev) => !prev);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        // navigate(`${PATHS.MEDIA}${item.id}`);
        navigate(`${PATHS.MEDIA_CONTENT}${item.id}`);
        break;

      case "gallery":
        setItemForGalery(item);
        break;

      case "protect":
      case "unprotect":
        REQUESTS.VOD.MEDIAS.BULK_PROTECT({
          id: item.id.toString(),
          is_protected: !item.is_protected,
        })

          .then((response) => {
            if (response.error) {
              notification.error({
                message: "Error",
                description: response.message,
              });

              return;
            }

            notification.success({
              message: "Success",
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

        break;

      case "delete":
        REQUESTS.VOD.MEDIAS.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

      default:
        break;
    }
  };
  const goToMediaContent = (mediaType) => {
    navigate(`${PATHS.MEDIA_CONTENT_NEW}`);
    setInLocal("mediaType", mediaType);
  };

  return (
    <PageComponent routes={["Vod", "Medias"]}>
      <SeriesTable
        getAgain={getAgain}
        setGetAgain={setGetAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        setPlaying={setPlaying}
        onHighlighted={onHighlighted}
        onEnabled={onEnabled}
        goToMediaContent={goToMediaContent}
      />

      <Player
        type="hls"
        showVideoAudioSubtitleTracks={true}
        isVisible={playing ? true : false}
        src={playing?.stream_url}
        selectedTvShow={playing?.type === "tv_show" ? playing.id : ""}
        hideModal={() => setPlaying(null)}
        useToken={playing?.stream_type === "internal" ? true : false}
      />

      <GalleryDrawer
        open={itemForGalery ? true : false}
        onClose={() => setItemForGalery(null)}
        movie={itemForGalery}
      />
    </PageComponent>
  );
}
