import Icon from "@ant-design/icons";
import ICONS from "../../config/icons";

import PATHS from "../../config/paths";
import MessagesMenu from "./MessagesMenu";

export default function checkPermissions(permissions) {
  let list = [
    {
      label: "Dashboard",
      key: PATHS.DASHBOARD_CONTENT,
      icon: <Icon component={() => ICONS.DASHBOARD} />,
    },
  ];

  if (permissions?.Vod_Manager) {
    list.push({
      label: "Videos",
      key: "vod-manager",
      icon: <Icon component={() => ICONS.VOD} />,
      children: [
        {
          key: PATHS.MEDIAS,
          label: "Videos",
        },
        {
          key: PATHS.SERIES,
          label: "Series",
        },
        {
          key: PATHS.COLLECTION,
          label: "Collection",
        },
        {
          key: PATHS.GENRES,
          label: "Genres",
        },
        {
          key: PATHS.CAST,
          label: "Cast",
        },
        {
          key: PATHS.MEDIA_TRANSCODING,
          label: "Uploads",
        },
        {
          key: PATHS.CONTENT_PROVIDER,
          label: "Content Providers",
        },
      ],
    });
  }

  if (permissions?.LIVE_TV) {
    list.push({
      label: "Live TV",
      key: "live-tv",
      icon: <Icon component={() => ICONS.LIVE_TV} />,

      children: [
        {
          key: PATHS.LIVE_TV,
          label: "Live TV",
        },
        {
          key: PATHS.LIVE_TV_CATEGORIES,
          label: "Categories",
        },
        permissions?.EPG && {
          label: "EPG",
          key: PATHS.EPG,
          // icon: <Icon component={() => ICONS.EPG} />,
        },

        permissions?.Archive && {
          label: "Archiver",
          key: "catch-up",
          // icon: <Icon component={() => ICONS.ARCHIVE} />,
          children: [
            {
              key: PATHS.CATCH_UP_SERVERS,
              label: "Servers",
            },

            {
              key: PATHS.CATCH_UP_RECORDINGS,
              label: "Recordings",
            },
          ],
        },

        permissions?.Streaming && {
          label: "Streamer",
          key: "streamer",
          // icon: <Icon component={() => ICONS.STREAM} />,
          children: [
            {
              key: PATHS.STREAM_SERVERS,
              label: "Servers",
            },

            {
              key: PATHS.STREAMS,
              label: "Streams",
            },
          ],
        },

        permissions?.Transcoding && {
          label: "Transcoders",
          key: "transcoders",
          // icon: <Icon component={() => ICONS.TRANSCODING} />,
          children: [
            {
              key: PATHS.TRANSCODERS_GROUPS,
              label: "Groups",
            },

            {
              key: PATHS.TRANSCODERS_SERVERS,
              label: "Servers",
            },

            {
              key: PATHS.TRANSCODING,
              label: "Transcoding",
            },

            {
              key: PATHS.TRANSCODERS_RESOLUTIONS,
              label: "Resolutions",
            },
          ],
        },
      ],
    });
  }

  if (permissions?.chat_message) {
    list.push({
      label: <MessagesMenu />,
      key: PATHS.MESSAGES,
      icon: <Icon component={() => ICONS.CHAT} />,
    });
  }

  if (permissions?.lessons || permissions?.quiz) {
    list.push({
      label: "Lessons",
      key: "lessons",
      icon: <Icon component={() => ICONS.LESSONS} />,
      children: [
        {
          key: PATHS.QUIZ,
          label: "Quiz",
        },
        {
          key: PATHS.LESSONS,
          label: "Lessons",
        },
      ],
    });
  }

  list.push(
    {
      label: "Users",
      key: "users",
      icon: <Icon component={() => ICONS.USERS} />,

      children: [
        {
          key: PATHS.USERS_GROUP,
          label: "Groups",
        },
        {
          key: PATHS.USERS,
          label: "Users",
        },
      ],
    },
    {
      label: "Monetization",
      key: "payments",
      icon: <Icon component={() => ICONS.PAYMENTS} />,

      children: [
        permissions?.Ads && {
          label: "Advertisement (AVOD)",
          key: PATHS.ADS,
        },
        {
          key: PATHS.PAYMENT_SUBSCRIPTION,
          label: "Subscriptions (SVOD)",
        },
        permissions?.Vod_Manager && {
          key: PATHS.TVOD_PAYMENT,
          label: "PPTV (TVOD)",
        },

        {
          key: PATHS.PAYMENT_CONFIGS,
          label: "Configs",
        },

        {
          key: PATHS.PAYMENT_HISTORY,
          label: "History",
        },
      ],
    }
    // {
    //   label: "Email notification",
    //   key: PATHS.EMAIL_NOTIFICATION,
    //   icon: <Icon component={() => ICONS.NOTIFICATIONS} />,
    // },

    // {
    //   label: "Admin",
    //   key: PATHS.ADMIN,
    //   icon: <Icon component={() => ICONS.ADMIN} />,
    // }
  );

  if (permissions?.launcher) {
    list.push({
      label: "Launcher",
      key: "launcher",
      icon: <Icon component={() => ICONS.LAUNCHER} />,

      children: [
        {
          key: PATHS.LAUNCHER_APPS,
          label: "Apps",
        },

        {
          key: PATHS.LAUNCHER_DEVICES,
          label: "Devices",
        },

        {
          key: PATHS.LAUNCHER_MESSAGES,
          label: "Messages",
        },

        {
          key: PATHS.LAUNCHER_CONFIGS,
          label: "Configs",
        },
      ],
    });
  }

  if (permissions?.web_page) {
    list.push({
      label: "Web Page",
      key: "web-page",
      icon: <Icon component={() => ICONS.WEB} />,

      children: [
        {
          key: PATHS.WEB_SETTINGS,
          label: "Settings",
        },

        {
          key: PATHS.WEB_NEWS,
          label: "News",
        },

        {
          key: PATHS.WEB_FAQ,
          label: "FAQ",
        },

        {
          key: PATHS.WEB_FEATURE,
          label: "Feature",
        },

        {
          key: PATHS.WEB_SLIDER,
          label: "Slider",
        },

        {
          key: PATHS.WEB_PLATFORMS,
          label: "Platforms",
        },

        {
          key: PATHS.WEB_AVAILABLE_DEVICES,
          label: "Available devices",
        },
      ],
    });
  }

  list.push({
    label: "Application",
    key: "application",
    icon: <Icon component={() => ICONS.APPLICATION_SETTINGS} />,

    children: [
      {
        key: PATHS.APPLICATION_BASIC,
        label: "Basic Info",
      },
      {
        key: PATHS.APPLICATION_BRANDING,
        label: "Branding",
      },

      {
        key: PATHS.APPLICATION_LANGUAGES,
        label: "Languages",
      },

      {
        key: PATHS.APPLICATION_USER_ICONS,
        label: "User Icons",
      },
      {
        key: PATHS.MENU_CONFIG,
        label: "Menu config",
      },
      {
        key: PATHS.APPLICATION_RADIUSES,
        label: "Application radiuses ",
      },
    ],
  });

  // if (permissions?.EPG) {
  //   list.push({
  //     label: "EPG",
  //     key: PATHS.EPG,
  //     icon: <Icon component={() => ICONS.EPG} />,
  //   });
  // }

  // if (permissions?.Transcoding) {
  //   list.push({
  //     label: "Transcoders",
  //     key: "transcoders",
  //     icon: <Icon component={() => ICONS.TRANSCODING} />,

  //     children: [
  //       {
  //         key: PATHS.TRANSCODERS_GROUPS,
  //         label: "Groups",
  //       },

  //       {
  //         key: PATHS.TRANSCODERS_SERVERS,
  //         label: "Servers",
  //       },

  //       {
  //         key: PATHS.TRANSCODING,
  //         label: "Transcoding",
  //       },

  //       {
  //         key: PATHS.TRANSCODERS_RESOLUTIONS,
  //         label: "Resolutions",
  //       },
  //     ],
  //   });
  // }

  // if (permissions?.Streaming) {
  //   list.push({
  //     label: "Streamer",
  //     key: "streamer",
  //     icon: <Icon component={() => ICONS.STREAM} />,

  //     children: [
  //       {
  //         key: PATHS.STREAM_SERVERS,
  //         label: "Servers",
  //       },

  //       {
  //         key: PATHS.STREAMS,
  //         label: "Streams",
  //       },
  //     ],
  //   });
  // }

  // if (permissions?.Archive) {
  //   list.push({
  //     label: "Catch-up",
  //     key: "catch-up",
  //     icon: <Icon component={() => ICONS.ARCHIVE} />,

  //     children: [
  //       {
  //         key: PATHS.CATCH_UP_SERVERS,
  //         label: "Servers",
  //       },

  //       {
  //         key: PATHS.CATCH_UP_RECORDINGS,
  //         label: "Recordings",
  //       },
  //     ],
  //   });
  // }

  // list.push({
  //   label: "History",
  //   key: PATHS.HISTORY,
  //   icon: <Icon component={() => ICONS.HISTORY} />,
  // });

  if (permissions?.Import) {
    list.push({
      label: "Import",
      key: PATHS.IMPORT,
      icon: <Icon component={() => ICONS.IMPORT} />,
    });
  }

  list.push({
    label: "Settings",
    key: "settings",
    icon: <Icon component={() => ICONS.SETTINGS} />,
    children: [
      {
        label: "Admin",
        key: PATHS.ADMIN,
        // icon: <Icon component={() => ICONS.ADMIN} />,
      },
      {
        key: PATHS.SETTINGS,
        label: "General Settings",
      },

      // {
      //   key: PATHS.WEB_HOOKS,
      //   label: "Web Hooks",
      // },

      {
        key: PATHS.CDN,
        label: "CDN",
      },

      // {
      //   key: PATHS.DOCUMENTS,
      //   label: "Documents",
      // },

      {
        key: PATHS.STORE_LINKS,
        label: "Store Links",
      },
      {
        key: PATHS.SMTP_CONFIG,
        label: "SMTP config",
      },
      {
        key: PATHS.SOCIAL_MEDIA_AUTH,
        label: "Social OAuth",
      },

      // {
      //     key: PATHS.VERSIONS,
      //     label: "Versions",
      // },

      permissions?.update_with_apk && {
        key: PATHS.APKS,
        label: "APKs",
      },

      {
        key: PATHS.SERVICE_NOTIFICATIONS,
        label: "Service Notifications",
      },
      {
        label: "Last Activity",
        key: PATHS.HISTORY,
        // icon: <Icon component={() => ICONS.HISTORY} />,
      },
      // permissions?.hy
    ],
  });

  return list;
}
