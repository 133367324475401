import { List } from "antd";
import { useEffect, useState } from "react";

import icons from "../../../../../config/icons";

import VodDrawer from "./vod/VodDrawer";
import LiveTvDrawer from "./live-tv/LiveTvDrawer";
import REQUESTS from "../../../../../api/requests";
import { liveTvServiceId, vodServiceId } from "../../../../../config/statics";

export default function Content({
  editable,
  vodService,
  permissions,
  liveTvService,
  setVodSeervice,
  setLiveTvSeervice,
}) {
  const [isOpenVodDrawer, setIsOpenVodDrawer] = useState(false);
  const [isOpenLiveTvDrawer, setIsOpenLiveTvDrawer] = useState(false);

  const [selectedMoviesCount, setSelectedMoviesCount] = useState(0);
  const [selectedTvShowsCount, setSelectedTvShowsCount] = useState(0);

  const showDrawer = (key) => {
    if (key === "vod") {
      setIsOpenVodDrawer(true);
    }

    if (key === "live-tv") {
      setIsOpenLiveTvDrawer(true);
    }
  };

  const setContentCount = (type) => {
    if (editable) {
      const services = editable?.service;

      const liveTvService = services?.find(
        (service) => service?.serviceId === liveTvServiceId
      );

      const vodService = services?.find((service) => service?.serviceId === vodServiceId);

      const checkIsNotEmptyVod = /[^,]/.test(vodService?.value?.split(","));
      const checkIsNotEmptyLiveTv = /[^,]/.test(liveTvService?.value?.split(","));

      if (type === "vod") {
        return checkIsNotEmptyVod ? vodService?.value?.split(",")?.length : 0;
      } else if (type === "live_tv") {
        return checkIsNotEmptyLiveTv ? liveTvService?.value?.split(",")?.length : 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  // useEffect(() => {
  //     if (editable) {
  //         if (permissions?.LIVE_TV) {
  //             REQUESTS.PAYMENTS.SUBSCRIPTIONS.CONTENT({
  //                 filter: JSON.stringify(liveTvService),
  //                 serviceId: liveTvServiceId,
  //             })
  //                 .then((response) => {
  //                     if (response.message) {
  //                         setSelectedTvShowsCount(response.message?.total);
  //                     }
  //                 })
  //                 .catch((error) => {
  //                     console.log(error);
  //                 });
  //         }

  //         if (permissions?.Vod_Manager) {
  //             REQUESTS.PAYMENTS.SUBSCRIPTIONS.CONTENT({
  //                 filter: JSON.stringify(vodService),
  //                 serviceId: vodServiceId,
  //             })
  //                 .then((response) => {
  //                     if (response.message) {
  //                         setSelectedMoviesCount(response.message?.total);
  //                     }
  //                 })
  //                 .catch((error) => {
  //                     console.log(error);
  //                 });
  //         }
  //     }
  // }, [liveTvService, vodService, editable]);
  console.error("Content.jsx: ", setContentCount("vod"), setContentCount("live_tv"));

  return (
    <>
      <List
        dataSource={[
          permissions?.LIVE_TV &&
            editable && {
              id: 1,
              name: "Live Tv",
              key: "live-tv",
              icon: icons.VOD,
              description: `You have ${setContentCount("live_tv") || 0} selected live tv`,
            },

          permissions?.Vod_Manager &&
            editable && {
              id: 2,
              name: "Vod",
              key: "vod",
              icon: icons.LIVE_TV,
              description: `You have ${
                setContentCount("vod") || 0
              } selected moves and tv shows`,
            },
        ]}
        bordered={editable ? true : false}
        renderItem={
          editable
            ? (item) => {
                if (item) {
                  return (
                    <List.Item
                    // actions={[
                    //   <div
                    //     onClick={() => showDrawer(item.key)}
                    //     key={`a-${item.id}`}
                    //     style={{ cursor: "pointer" }}
                    //   >
                    //     View list
                    //   </div>,
                    // ]}
                    >
                      <List.Item.Meta title={item.name} description={item.description} />
                    </List.Item>
                  );
                }
              }
            : null
        }
      />
      {permissions?.Vod_Manager && (
        <VodDrawer
          open={isOpenVodDrawer}
          vodService={vodService}
          setVodSeervice={setVodSeervice}
          onClose={() => setIsOpenVodDrawer(false)}
          setSelectedMoviesCount={setSelectedMoviesCount}
        />
      )}

      {permissions?.LIVE_TV && (
        <LiveTvDrawer
          open={isOpenLiveTvDrawer}
          liveTvService={liveTvService}
          setLiveTvSeervice={setLiveTvSeervice}
          onClose={() => setIsOpenLiveTvDrawer(false)}
          setSelectedTvShowsCount={setSelectedTvShowsCount}
        />
      )}
    </>
  );
}
