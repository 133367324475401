import { useEffect, useState } from "react";

import {
  Drawer,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Space,
  Checkbox,
} from "antd";

import REQUESTS from "../../../api/requests";

import { useStreamerServersOptions } from "../../../hooks/selectOptions";

import Resolutions from "./Resolutions";

import ButtonComponent from "../../../_components/ButtonComponent";

export default function StreamerStreamsDrawer({ onClose, open, editable, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const serverOptions = useStreamerServersOptions();

  const [selectedResolutions, setSelectedResolutions] = useState([]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);
    const body = {
      name: values.name,
      serverId: values.serverId,
      encode_audio: values.encode_audio,
      resolutions: selectedResolutions,
      loop: false,
    };

    if (editable) {
      body.id = editable.id;

      REQUESTS.STREAMER.STREAMS.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.STREAMER.STREAMS.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message,
            });

            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setIsLoading(false);
    }

    if (editable) {
      form.setFields([
        { name: "name", value: editable.name },
        { name: "encode_audio", value: editable.encode_audio },
        { name: "serverId", value: editable.serverId },
        // { name: "resolutions", value: editable.resolutions },
      ]);

      const editableResolutions = editable.resolutions.map((item) => ({
        id: item.id,
        input: item.info.input,
        name: item.name,
        output: item.info.output,
      }));

      setSelectedResolutions(editableResolutions);

      // setSelectedResolutions(editable.resolutions);
    }
  }, [open, editable]);

  return (
    <Drawer
      width={600}
      title={`${editable ? "Edit" : "Add"} stream`}
      placement="right"
      onClose={onClose}
      open={open}
      destroyOnClose
    >
      <Form
        form={form}
        name="streamer-streams"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     form.submit();
        //   }
        // }}
        initialValues={{
          serverId: serverOptions[0]?.value,
          encode_audio: serverOptions[0]?.encode_audio,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Space>
          <Form.Item
            // label="Encode audio"
            name="encode_audio"
            rules={[
              {
                required: false,
                message: "Please input  time",
              },
            ]}
            valuePropName="checked"
            style={{ margin: "0 auto" }}
          >
            <Checkbox> Encode audio </Checkbox>
          </Form.Item>

          <Form.Item
            label="Server "
            name="serverId"
            rules={[
              {
                required: true,
                message: "Please select server",
              },
            ]}
          >
            <Select options={serverOptions} style={{ width: 390 }} />
          </Form.Item>
        </Space>

        {open && (
          <Resolutions
            editable={editable}
            setSelectedResolutions={setSelectedResolutions}
          />
        )}

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
